import Vue from "vue";
import VueRouter from "vue-router";
import "nprogress/nprogress.css";
Vue.use(VueRouter);
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/about",
    name: "about",
    component: () => import("../views/aboutUs/AboutUs.vue")
  },
  {
    path: "/",
    name: "home",
    redirect: "index",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index.vue"),
    children: [
      {
        path: "index",
        name: "Index",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Home.vue"),
      },
      {
        path: "online",
        name: "online",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/OnlineMessage.vue"),
      },
      {
        path: "research",
        name: "research",
        component: () => import ("../views/Research/index.vue")
      },
      {
        path: "cooperation",
        name: "cooperation",
        component: () =>
          import(
            /* webpackChunkName: "caseIndex" */ "../views/COOPERATION/index.vue"
          ),
      },
      {
        path: "aboutUs",
        name: "aboutUs",
        component: () =>
          import(/* webpackChunkName: "caseIndex" */ "../views/aboutUs/AboutUs.vue"),
      },
      {
        path: "display",
        name: "display",
        component: () =>
          import(
            /* webpackChunkName: "caseIndex" */ "../views/ProductDisplay/index.vue"
          ),
      },
      {
        path: "ppt",
        name: "ppt",
        component: () => import("../views/ProductDisplay/ppt.vue")
      },
      {
        path: "displayDetail",
        name: "displayDetail",
        component: () =>
          import(
            /* webpackChunkName: "caseIndex" */ "../views/ProductDisplay/detail.vue"
          ),
      },
      {
        path: "error",
        name: "Error",
        component: () =>
          import(/* webpackChunkName: "error" */ "@/views/error"),
        hidden: true,
      },
      {
        path: "mobileClient",
        name: "mobileClient",
        component: () =>
          import(/* webpackChunkName: "mobileClient" */ "../views/mobileClient/home.vue"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/error",
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
});
// NProgress.configure({ showSpinner: false });

router.beforeEach(async (to, from, next) => {
  next();
});

router.afterEach(() => {
  // message.destroy();
  // NProgress.done();
});
export default router;
